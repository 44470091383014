export function useHeadTagUtils() {
  const availableTags = [
    { key: 'title', label: 'Title', description: 'The title of the page' },
    { key: 'description', label: 'Description', description: 'A brief description of the page' },
    { key: 'keywords', label: 'Keywords', description: 'Keywords related to the page content' },
    { key: 'canonical', label: 'Canonical', description: 'Reference the "original" url when the page contains mostly duplicate content.' },
    { key: 'script', label: 'Script', description: 'Add an inline script to the head' },
    { key: 'externalScript', label: 'External script', description: 'Add a remote script to the head' },
    { key: 'noscript', label: 'noscript', description: 'Alternate content to be displayed to users that have disabled scripts in their browser.' },
    { key: 'structuredDataMarkup', label: 'Structured Data', description: 'Add SEO structured data' },
    { key: 'style', label: 'Style', description: 'Add style tag' },
    { key: 'author', label: 'Author', description: 'The author of the page' },
    { key: 'robots', label: 'Robots', description: 'Instructions for search engine robots' },
    { key: 'viewport', label: 'Viewport', description: 'The viewport settings for responsive web design' },
    { key: 'og:title', label: 'Open Graph Title', description: 'The title of your content' },
    { key: 'og:description', label: 'Open Graph Description', description: 'The description of your content' },
    { key: 'og:type', label: 'Open Graph Type', description: 'The type of your content' },
    { key: 'og:url', label: 'Open Graph URL', description: 'The URL of your content' },
    { key: 'og:image', label: 'Open Graph Image', description: 'The URL of the image to display' },
    { key: 'og:image:alt', label: 'Open Graph Image Alt', description: 'Alternative text for the image' },
    { key: 'og:image:width', label: 'Open Graph Image Width', description: 'The width of the image in pixels' },
    { key: 'og:image:height', label: 'Open Graph Image Height', description: 'The height of the image in pixels' },
    { key: 'og:site_name', label: 'Open Graph Site Name', description: 'The name of your website' },
    { key: 'og:locale', label: 'Open Graph Locale', description: 'The locale code, e.g., en_US' },
    { key: 'fb:app_id', label: 'Facebook App ID', description: 'Your Facebook app ID' },
    { key: 'fb:admins', label: 'Facebook Admins', description: 'The Facebook user IDs of the page admins' },
    { key: 'theme-color', label: 'Theme Color', description: 'The color theme for the browser address bar and other UI elements' },
    { key: 'format-detection', label: 'Format Detection', description: 'Controls automatic detection of certain types of content by the browser' },
    { key: 'apple-mobile-web-app-title', label: 'Apple Mobile Web App Title', description: 'The title for a web app displayed on the home screen of an Apple device' },
    { key: 'apple-mobile-web-app-capable', label: 'Apple Mobile Web App Capable', description: 'Indicates whether a web app is capable of running in full-screen mode on an Apple device' },
    { key: 'apple-mobile-web-app-status-bar-style', label: 'Apple Mobile Web App Status Bar Style', description: 'The appearance of the status bar for a web app on an Apple device' },
    { key: 'apple-touch-icon', label: 'Apple Touch Icon', description: 'The image used as the app icon when bookmarked on an Apple device' },
    { key: 'msapplication-TileColor', label: 'Microsoft Tile Color', description: 'The background color for a tile when pinned to the Start menu in Windows' },
    { key: 'msapplication-TileImage', label: 'Microsoft Tile Image', description: 'The image used as a tile icon when pinned to the Start menu in Windows' },
    { key: 'application-name', label: 'Application Name', description: 'The name of the web application' },
    { key: 'msapplication-tooltip', label: 'Microsoft Application Tooltip', description: 'The tooltip text displayed when the user hovers over the app icon in Windows' },
    { key: 'msapplication-starturl', label: 'Microsoft Start URL', description: 'The URL where the app should start when launched from the Start menu in Windows' },
    { key: 'msapplication-navbutton-color', label: 'Microsoft Navigation Button Color', description: 'The color of navigation buttons in the browser UI on Windows' },
    { key: 'article:published_time', label: 'Article Published Time', description: 'The publication date and time of an article' },
    { key: 'article:modified_time', label: 'Article Modified Time', description: 'The last modified date and time of an article' },
    { key: 'article:author', label: 'Article Author', description: 'The author of an article' },
    { key: 'article:section', label: 'Article Section', description: 'The section category of an article' },
    { key: 'article:tag', label: 'Article Tag', description: 'A tag related to an article' },
    { key: 'twitter:card', label: 'Twitter Card', description: 'The type of Twitter card to use for social sharing' },
    { key: 'twitter:title', label: 'Twitter Title', description: 'The title of your content' },
    { key: 'twitter:description', label: 'Twitter Description', description: 'The description of your content' },
    { key: 'twitter:image', label: 'Twitter Image', description: 'The URL of the image to display' },
    { key: 'twitter:image:alt', label: 'Twitter Image Alt', description: 'Alternative text for the image' },
    { key: 'twitter:site', label: 'Twitter Site', description: 'Your Twitter username or site handle' },
    { key: 'twitter:creator', label: 'Twitter Creator', description: 'Twitter handle of the content creator' },
    { key: 'twitter:card', label: 'Twitter Card', description: 'The type of Twitter card to use for social sharing' },
    { key: 'twitter:site:id', label: 'Twitter Site ID', description: 'The Twitter user ID of the site associated with the content' },
    { key: 'twitter:creator:id', label: 'Twitter Creator ID', description: 'The Twitter user ID of the content creator' },
    { key: 'twitter:data1', label: 'Twitter Data 1', description: 'Additional data field for Twitter cards' },
    { key: 'twitter:label1', label: 'Twitter Label 1', description: 'Label for the first data field on Twitter cards' },
    { key: 'twitter:data2', label: 'Twitter Data 2', description: 'Additional data field for Twitter cards' },
    { key: 'twitter:label2', label: 'Twitter Label 2', description: 'Label for the second data field on Twitter cards' },
    { key: 'twitter:domain', label: 'Twitter Domain', description: 'The domain of the website associated with the content' },
    { key: 'twitter:app:name:iphone', label: 'Twitter App Name (iPhone)', description: 'The name of the iPhone app associated with the content' },
    { key: 'twitter:app:id:iphone', label: 'Twitter App ID (iPhone)', description: 'The ID of the iPhone app associated with the content' },
    { key: 'twitter:app:url:iphone', label: 'Twitter App URL (iPhone)', description: 'The URL to open when the iPhone app is installed' },
    { key: 'twitter:app:name:ipad', label: 'Twitter App Name (iPad)', description: 'The name of the iPad app associated with the content' },
    { key: 'twitter:app:id:ipad', label: 'Twitter App ID (iPad)', description: 'The ID of the iPad app associated with the content' },
    { key: 'twitter:app:url:ipad', label: 'Twitter App URL (iPad)', description: 'The URL to open when the iPad app is installed' },
    { key: 'twitter:app:name:googleplay', label: 'Twitter App Name (Google Play)', description: 'The name of the Google Play app associated with the content' },
    { key: 'twitter:app:id:googleplay', label: 'Twitter App ID (Google Play)', description: 'The ID of the Google Play app associated with the content' },
    { key: 'twitter:app:url:googleplay', label: 'Twitter App URL (Google Play)', description: 'The URL to open when the Google Play app is installed' },
    { key: 'twitter:player', label: 'Twitter Player', description: 'The HTTPS URL of an iframe player to display in a Twitter card' },
    { key: 'twitter:player:width', label: 'Twitter Player Width', description: 'The width of the Twitter player' },
    { key: 'twitter:player:height', label: 'Twitter Player Height', description: 'The height of the Twitter player' },
    { key: 'twitter:player:stream', label: 'Twitter Player Stream', description: 'The URL to a live video stream to display in a Twitter card' },
    { key: 'twitter:app:country', label: 'Twitter App Country', description: 'The ISO 3166-1 alpha-2 country code for the country where the app is available' },
    { key: 'twitter:app:iphone:url', label: 'Twitter App iPhone URL', description: 'The custom URL scheme to use when opening the iPhone app' },
    { key: 'twitter:app:ipad:url', label: 'Twitter App iPad URL', description: 'The custom URL scheme to use when opening the iPad app' },
    { key: 'twitter:app:googleplay:url', label: 'Twitter App Google Play URL', description: 'The custom URL scheme to use when opening the Google Play app' },
    { key: 'al:ios:app_name', label: 'Apple iOS App Name', description: 'The name of the iOS app associated with the content' },
    { key: 'al:ios:app_store_id', label: 'Apple iOS App Store ID', description: 'The ID of the iOS app in the App Store' },
    { key: 'al:ios:url', label: 'Apple iOS URL', description: 'The URL to open when the iOS app is installed' },
    { key: 'al:android:app_name', label: 'Android App Name', description: 'The name of the Android app associated with the content' },
    { key: 'al:android:package', label: 'Android Package', description: 'The package name of the Android app' },
  ]

  return { availableTags }
}
